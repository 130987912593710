import './Concerts.css';
import ConcertsCard from './ConcertCard/ConcertCard';
import db from '../../firebase';
import { collection,getDocs } from 'firebase/firestore';
import { useEffect,useState } from 'react';

export default function Concerts({loadingSuccess}) {

    const concertsRef = collection(db, 'concerts');


    const [concerts, setConcerts] = useState([]);


    useEffect(()=>{
      getDocs(concertsRef).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setConcerts(doc.data().concerts);
          loadingSuccess()
        });
      }).catch((error) => {
        console.error('Error getting documents: ', error);
      });
    },[])


    function toDate(timestamp) {
      return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6);
  }
  
  concerts.sort((a, b) => toDate(a.date) - toDate(b.date));

    return(
        <div className="concerts" id="concerts">
            <h2>Концерты</h2>
            <div className="concerts__list">
                {concerts && concerts.map((concert, index) => (
                    <ConcertsCard key={index} concert={concert} />
                ))}
            </div>
        </div>
    )
}